import React, { createRef, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import $ from 'jquery';

interface Props {
    id: string,
    title: string,
    style?: React.CSSProperties
}

const Section = (props: React.PropsWithChildren<Props>) => {
    return (
        <div id={props.id} className='section p-3 shadow' style={{ ...props.style, width: 1100, maxWidth: '100%', backdropFilter: 'blur(5px)', scrollMargin: 56 }}>
            <a href={`/#${props.title}`} />
            <div className='border-bottom py-1 w-100 mb-3'>
                <h3 className='text-uppercase fw-bold'>{props.title}</h3>
            </div>

            {props.children}
        </div>
    )
}

export default Section;