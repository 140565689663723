import React, { createRef, useEffect, useRef } from 'react';
import p5Types from 'p5';
import Sketch from 'react-p5'

class Wire {
    cells: Cell[];
    last: number;

    constructor(start: Cell, last: number) {
        this.cells = [start];
        this.last = last;
    }
}

class Cell {
    x: number;
    y: number;
    available: boolean;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
        this.available = true;
    }
}

const Background = () => {
    const grid = useRef<Cell[][]>([]);
    const available = useRef<Cell[]>([]);
    const wires = useRef<Wire[]>([]);

    const cellSize = 20;
    const wireLength = 20;
    const cutOffLength = 2;
    const straightness = 6;

    const dirs = [
        [-1, -1], [0, -1], [1, -1],
        [1, 0],
        [1, 1], [0, 1], [-1, 1],
        [-1, 0]
    ];

    const renderWire = (p5: p5Types, wire: Wire) => {
        p5.noFill();
        p5.strokeWeight(cellSize / 4);
        p5.stroke(42, 46, 50);
        p5.beginShape();
        for (var i = 0; i < wire.cells.length; i++) {
            var cell = wire.cells[i];
            p5.vertex((cell.x + .5) * cellSize, (cell.y + .5) * cellSize);
        }
        p5.endShape();
        Math.random() > .5 ? p5.fill(33, 37, 41) : p5.fill(42, 46, 50);
        p5.strokeWeight(cellSize / 6);
        var end = wire.cells.length - 1;
        p5.ellipse((wire.cells[0].x + .5) * cellSize, (wire.cells[0].y + .5) * cellSize, cellSize * .7);
        p5.ellipse((wire.cells[end].x + .5) * cellSize, (wire.cells[end].y + .5) * cellSize, cellSize * .7);
    }

    const generateWire = (wire: Wire, gridWidth: number, gridHeight: number) => {
        var hasSpace = true;
        while (wire.cells.length < wireLength && hasSpace) {
            var prevCell = wire.cells[wire.cells.length - 1];
            var tries = [0, 1, -1];
            if (Math.random() > .5) tries = [0, -1, 1];
            var found = false;
            hasSpace = false;

            while (tries.length > 0 && !found) {
                var mod = tries.splice(Math.floor(Math.pow(Math.random(), straightness) * tries.length), 1)[0];
                var index = wire.last + 4 + mod;
                if (index < 0) index += 8;
                if (index > 7) index -= 8
                var dir = dirs[index];

                var x = dir[0] + prevCell.x;
                var y = dir[1] + prevCell.y;
                if (x >= 0 && x < gridWidth - 1 && y >= 0 && y < gridHeight - 1) {
                    var cell = grid.current[x][y];
                    if (cell.available && noCrossOver(index, x, y)) {
                        wire.cells.push(cell);
                        cell.available = false;
                        hasSpace = found = true;
                        wire.last = wire.last + mod;
                        if (wire.last < 0) wire.last += 8;
                        if (wire.last > 7) wire.last -= 8;
                    }
                }
            }
        }
    }

    const noCrossOver = (index: number, x: number, y: number) => {
        if (index == 0) return (grid.current[x + 1][y].available || grid.current[x][y + 1].available);
        if (index == 2) return (grid.current[x - 1][y].available || grid.current[x][y + 1].available);
        if (index == 4) return (grid.current[x - 1][y].available || grid.current[x][y - 1].available);
        if (index == 6) return (grid.current[x + 1][y].available || grid.current[x][y - 1].available);
        return true;
    }

    const findOpenDir = (x: number, y: number, gridWidth: number, gridHeight: number) => {
        var checks = [0, 1, 2, 3, 4, 5, 6, 7];
        while (checks.length > 0) {
            var index = checks.splice(Math.floor(Math.random() * checks.length), 1)[0];
            var dir = dirs[index];
            var x2 = x + dir[0];
            var y2 = y + dir[1];
            if (x2 >= 0 && x2 < gridWidth - 1 && y2 >= 0 && y2 < gridHeight - 1) {
                if (grid.current[x2][y2].available) return index;
            }
        }
        return 0;
    }

    const recreate = (p5: p5Types) => {

        const gridWidth = Math.ceil(p5.width / cellSize) + 1;
        const gridHeight = Math.ceil(p5.height / cellSize) + 1

        grid.current = [];
        available.current = [];
        wires.current = [];

        for (var i = 0; i < gridWidth; i++) {
            grid.current.push([]);
            for (var j = 0; j < gridHeight; j++) {
                var cell = new Cell(i, j);
                grid.current[i][j] = cell;
                available.current.push(cell);
            }
        }

        while (available.current.length > 0) {
            var cell = available.current[Math.floor(Math.random() * available.current.length)];
            cell.available = false;
            var wire = new Wire(cell, findOpenDir(cell.x, cell.y, gridWidth, gridHeight));
            generateWire(wire, gridWidth, gridHeight);
            wires.current.push(wire);
            for (var i = 0; i < wire.cells.length; i++) {
                available.current.splice(available.current.indexOf(wire.cells[i]), 1);
            }
        }
        p5.loop();
    }

    const draw = (p5: p5Types) => {
        p5.background(33, 37, 41);
        if (!grid.current || !wires.current)
            return;

        for (var i = 0; i < wires.current.length; i++) {
            if (wires.current[i].cells.length > cutOffLength) {
                renderWire(p5, wires.current[i]);
            };
        }
        p5.noLoop();
    }

    const resize = (p5: p5Types) => {
        p5.resizeCanvas(document.body.scrollWidth, document.body.scrollHeight);
        recreate(p5);
    }

    const setup = (p5: p5Types, canvas: Element) => {
        p5.createCanvas(document.body.scrollWidth, document.body.scrollHeight).parent(canvas);
        p5.ellipseMode('center');
        recreate(p5);
    }

    return (
        <div className='position-absolute' style={{ zIndex: -1, top: 0, right: 0, left: 0, bottom: 0 }}>
            <Sketch setup={setup} draw={draw} windowResized={resize} />
        </div>
    )
}

export default Background;