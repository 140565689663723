import React, { useState } from 'react';
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

const Header = () => {
    const [expanded, setExpanded] = useState<boolean | undefined>(undefined);

    return (
        <Navbar fixed='top' expand='md' className='bg-dark shadow' expanded={expanded}>
            <Container>
                <Navbar.Toggle className='ms-auto' aria-controls='navbar' onClick={() => setExpanded(expanded ? undefined : true)} />
                <Navbar.Collapse id='navbar'>
                    <Nav className='me-auto fw-bold text-uppercase align-items-start align-items-md-center'>
                        <Nav.Link href='/#about' onClick={() => setExpanded(false)} className='mx-2 about'>About</Nav.Link>
                        <Nav.Link href='/#professional' onClick={() => setExpanded(false)} className='mx-2 professional'>Professional</Nav.Link>
                        <Nav.Link href='/#projects' onClick={() => setExpanded(false)} className='mx-2 projects'>Projects</Nav.Link>
                        <Nav.Link href='/#contact' onClick={() => setExpanded(false)} className='mx-2 contact'>Contact</Nav.Link>
                        <Nav className='mx-2'>
                            <Nav.Link href='https://linkedin.com/in/tristan-attebery' onClick={() => setExpanded(false)} target='_blank' className='linkedin me-2'><i className='fa-brands fa-linkedin' /></Nav.Link>
                            <Nav.Link href='https://twitter.com/tristanattebery' onClick={() => setExpanded(false)} target='_blank' className='twitter me-2'><i className='fa-brands fa-x-twitter' /></Nav.Link>
                        </Nav>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header;