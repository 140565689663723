import React, { createRef, useState } from 'react';
import $ from 'jquery';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { Badge } from 'react-bootstrap';

interface Props {
  title: string,
  techList: string[]
}

const TimeNode = (props: React.PropsWithChildren<Props>) => {

  const bodyRef = createRef<HTMLDivElement>();

  const [open, setOpen] = useState<boolean>(false);

  const toggle = () => {
    if (bodyRef.current) {
      setOpen(!open);
      $(bodyRef.current).slideToggle();
    }
  }

  return (
    <div className='p-3 mb-3 border-start'>
      <div onClick={toggle} className='d-flex align-items-center hover'>
        {props.title} {open ? <FaChevronDown className='ms-3' /> : <FaChevronRight className='ms-3' />}
      </div>
      <div ref={bodyRef} style={{ display: 'none' }}>
        {props.children}
        <div>
          {props.techList.map(x =>
            <Badge key={`${props.title}-${x}`} bg='secondary' className='me-2 text-dark'>{x}</Badge>
          )}
        </div>
      </div>
    </div>

  );
}

export default TimeNode;