import { httpsCallable, getFunctions } from 'firebase/functions';
import { CloudFunctionReturn } from '../models/CloudFunctionReturn';

export default class ContactProvider {
    static QueueContactEmail = (name: string, email: string, message: string): Promise<CloudFunctionReturn> => {
        const queueEmail = httpsCallable(getFunctions(), 'sendContactEmail');
        return queueEmail({ name, email, message }).then((result) => {
            const response = result.data as CloudFunctionReturn;
            return response;
        }).catch((e) => {
            return { success: false, message: e?.message || 'There was an error submitting the message.' };
        });
    }
}