import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.scss';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/home/Home';
import Background from './shared.tsx/Background';
import Header from './shared.tsx/Header';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyCNeZdyVeQsTFhchn6IkwV1QjMoKM3ttrc",
  authDomain: "portfolio-site-ca0e4.firebaseapp.com",
  projectId: "portfolio-site-ca0e4",
  storageBucket: "portfolio-site-ca0e4.appspot.com",
  messagingSenderId: "662951320043",
  appId: "1:662951320043:web:59a6b4054dacbee8a721ae",
  measurementId: "G-P8VPQG447S"
};

function App() {

  const { pathname, hash, key } = useLocation();
  const app = initializeApp(firebaseConfig);
  const appCheck = initializeAppCheck(app, { provider: new ReCaptchaV3Provider('6Ldx3GMpAAAAAFH8hUiBIN0vki1dPnyyXyWzd-V6'), isTokenAutoRefreshEnabled: true });
  const analytics = getAnalytics(app);

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
      </Routes>
      <Background />
    </>
  );
}

export default App;
